.admin__header {
    width: 100%;
    padding: 20px 0px;
    background: #FFEFEF;
}

.admin__nav-top {
    width: 100%;
}

.admin__nav-wrapper-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5rem;
}

.admin__nav-top-right img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
}

.admin__nav-top-right {
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.admin__nav-wrapper-top .search__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.admin__nav-top-right span i {
    color: var(--primary--color);
    cursor: pointer;
}

.admin__nav-wrapper-top .logo h2 {
    font-size: 1.2rem;
    color: var(--primary--color);
}

.admin__menu {
    width: 100%;
    height: 70px;
    line-height: 70px;
    background: white;
}

.admin__navigation {
    text-align: center;
}

.admin__menu-list {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    justify-content: center;
}

.admin__menu-list
.admin__menu-item a {
    font-weight: 500;
    padding: 10px 15px;
}

.admin__nav-top-right .profile__actions {
    position: absolute;
    top: 70px;
    width: 180px;
    z-index: 10;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--primary-color);
    color: #ddd;
    line-height: 30px;
    display: none;
    cursor: pointer;
}
