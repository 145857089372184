.color-selector {
    display: inline-block;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 3px;
    position: relative;
    padding: 6px 32px 6px 13px;
    font-family: verdana;
    background: white;
    border: #bababa solid 1px;
    
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: black transparent transparent transparent;
      position: absolute;
      right: 10px;
      top: 17px;
    }
    
    .circle {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #eee;
      margin-right: 10px;
    }
    
    .hidden {
      position: absolute;
      left: 0;
      opacity: 0;
    }
    
    span {
      display: inline-block;
      vertical-align: middle;
    }
}

.color-holder {
    display: inline-block;
    position: relative;
    background: #fff;    
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #eee;
    margin-left: 2%;

    .hidden {
      position: absolute;
      left: 0;
      opacity: 0;
    }

    &:hover {
      /* transform: scale(1.25);  */
    }
}

.color-holder.active{
    border: 1.5px #fff solid !important;
    width: 33px !important;
    height: 33px !important;
    box-shadow: -7px -7px 16px 0 #FFFFFF, -7px 4px 11px -4px;
}